*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  background-color: #000029;
  width: 100vw !important;
  height: 100vh !important;
  font-family: "Work Sans", sans-serif;
}

/* .backgroundimg { */
  /* background-image: url("../public/bg2.png"); */
  /* background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover; */
/* } */

.ant-steps-item-title {
  color: #e0e0e0; /* Default color for titles */
}

.ant-steps-item-description {
  color: #b0b0b0; /* Default color for descriptions */
}

.ant-steps-item-finish .ant-steps-item-title,
.ant-steps-item-finish .ant-steps-item-description {
  color: #ffffff; /* Color for finished steps */
}

.ant-steps-item-process .ant-steps-item-title,
.ant-steps-item-process .ant-steps-item-description {
  color: #1890ff; /* Color for in-progress steps */
}

.ant-steps-item-wait .ant-steps-item-title,
.ant-steps-item-wait .ant-steps-item-description {
  color: #8c8c8c; /* Color for waiting steps */
}

.inactive_icon{
  background-color: #21234A ;
  fill: #6D71A3;
}

.active_icon{
  background-color: #0475FF ;
  fill: #ffffff;
}

input{
  color: white ;
}

input:focus{
  outline: none !important;
}

.spinning_indicator{
  position: absolute;
  width: 100%;
  height: 90vh;
  top: 10vh;
  z-index: 1000;
  background-color: white;
  opacity: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-19bb58m,.css-1dimb5e-singleValue{
  color: white !important;
}

.css-1jqq78o-placeholder{
  color: #9CA3B0 !important;
}



